import { ColumnsMeta } from 'src/pages/AuthPage/_BLL/types';
import { columnsMetaHelper } from '../../../../../../../../../lib/columnsMetaHelpers';

export const organizePeerTableData = (peerTableData: any) => {
	const organizedTableData: any = [];

	const currentCompany = {
		id: peerTableData.company.id,
		name: peerTableData.company.name,
		...peerTableData.company.dataPoints,
	};

	organizedTableData.push(currentCompany);

	organizedTableData.push({
		id: peerTableData.peers.id,
		name: peerTableData.peers.name,
		...peerTableData.peers.dataPoints,

		subRows: [
			currentCompany,
			...peerTableData.peers.children.map((child: any) => ({
				id: child.id,
				name: child.name,
				...child.dataPoints,
			})),
		],
	});

	return organizedTableData;
};

export const createColumns = (columnsIds: Array<number> | null, columnsMeta: ColumnsMeta | null) => {
	if (columnsIds) {
		return columnsIds.map(id => ({
			Header: columnsMeta ? columnsMetaHelper.getNameById(id, columnsMeta.dataPoints) : '',
			accessor: `${id}`,
			sortType: 'basic',
		}));
	}

	return [];
};

export const getTableIds = (columnsObject: Record<string, string>): Array<number> => {
	return Object.keys(columnsObject).map(item => +item);
};
