import { RequestStatus } from 'src/shared/api/types';
import { TableData } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../../../app/redux/createAction';
import { apiRequest } from 'src/shared/api/api';
import { getTableIds } from '../../ui/content/_company/CompanyContentA/_options/Peer/lib/helpers';

const NAME = 'peer_table';

// * Async thunks
export const getPeerTable = createAppAsyncThunk(`${NAME}/getPeerTable`, async (arg: { url: string; params: object }, thunkAPI) => {
	const { getState, dispatch } = thunkAPI;
	const state = getState();
	const { url, params } = arg;

	const currentColumns = state.peerTable.columnsIds;

	const payload = {
		ids: currentColumns,
	};

	const tableData = await apiRequest.postRequest<TableData>({
		url,
		payload,
		params,
		thunkAPI,
	});

	if (currentColumns === null) {
		const columns = getTableIds(tableData.company.dataPoints);
		dispatch(actionsPeerTable.setColumns({ columns }));
	}

	return tableData;
});

// * Reducer
interface State {
	tableData: TableData;
	columnsIds: Array<number> | null;
	status: RequestStatus;
}

export const initialState: State = {
	tableData: null,
	columnsIds: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setColumns: (state, action: { payload: { columns: number[] | null } }) => {
			state.columnsIds = action.payload.columns;
		},
	},
	extraReducers: builder => {
		builder.addCase(getPeerTable.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getPeerTable.fulfilled, (state, action) => {
			state.tableData = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getPeerTable.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsPeerTable = {
	...slice.actions,
	getPeerTable,
};
