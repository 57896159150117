import {
	CUSIP_ACCESSOR,
	CUSIP_MATCHED_DP,
	ISIN_ACCESSOR,
	ISIN_MATCHED_DP,
	LEI_ACCESSOR,
	LEI_MATCHED_DP,
	SEDOL_ACCESSOR,
	SEDOL_MATCHED_DP,
} from 'src/pages/MainSearchResultsPage/consts';
import { SelectedColumn } from '../../customizeColumns/types';
import { SearchResultCompany } from '../../searchResults/types';

export const parseCompaniesForTable = (companies: SearchResultCompany[], activeColumns: SelectedColumn[]) => {
	return companies.map(company => {
		const parsedProperties: Record<string, string> = {};

		const isins = company.dataPoints[ISIN_MATCHED_DP]?.[8];
		isins && Object.assign(parsedProperties, { [ISIN_ACCESSOR]: isins });

		const leis = company.dataPoints[LEI_MATCHED_DP]?.[8];
		leis && Object.assign(parsedProperties, { [LEI_ACCESSOR]: leis });

		const cusip = company.dataPoints[CUSIP_MATCHED_DP]?.[8];
		cusip && Object.assign(parsedProperties, { [CUSIP_ACCESSOR]: cusip });

		const sedol = company.dataPoints[SEDOL_MATCHED_DP]?.[8];
		sedol && Object.assign(parsedProperties, { [SEDOL_ACCESSOR]: sedol });

		activeColumns.forEach(column => {
			Object.entries(company.dataPoints).forEach(([key, value]) => {
				if (key === column.id.toString()) {
					parsedProperties[column.tableHeaderName] = column.reportingPeriodId ? value[column.reportingPeriodId.toString()] : Object.values(value)[0];
				}
			});
		});

		return {
			id: company.id,
			name: company.name,
			...parsedProperties,
		};
	});
};
