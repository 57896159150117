import { Button, ButtonProps } from '@mui/material';
import { FC, memo } from 'react';
import { RequestStatus } from 'src/shared/api/types';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import s from './ButtonsSquared.module.scss';

interface Props extends ButtonProps {
	status?: RequestStatus;
}

export const ButtonSquared: FC<Props> = memo(props => {
	const {
		status, //
		disabled,
		children,
		color = 'primary',
		variant = 'contained',
		className,
		...buttonProps
	} = props;

	const renderLoader = () => {
		return status === RequestStatus.loading ? <Preloader style={{ width: '1rem', height: '1rem' }} /> : '';
	};

	const isDisabled = () => {
		if (status !== undefined) {
			return status === RequestStatus.loading || disabled;
		} else {
			return disabled;
		}
	};

	return (
		<Button
			{...buttonProps}
			className={className}
			disabled={isDisabled()}
			color={color}
			variant={variant}
		>
			<span className={s.inner}>
				{children}
				{renderLoader()}
			</span>
		</Button>
	);
});
