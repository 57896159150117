import { DataPoint } from 'src/pages/AuthPage/_BLL/types';
import { db } from '../init';

export const addDataPointTree = async (dataPointTree: DataPoint[]) => {
	try {
		await db.dataPointTree.bulkAdd(dataPointTree);
	} catch (error) {
		console.log(error);
	}
};
