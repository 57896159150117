import { Font } from '@react-pdf/renderer';
import { AppDecorator } from 'src/app/decorators/AppDecorator';
import { NotificationsProvider } from 'src/app/providers/NotificationsProvider';
import { AppRouter } from 'src/app/router/ui/AppRouter';

export const App = () => {
	Font.register({
		family: 'Poppins, sans-serif',
		fonts: [
			{
				src: '/fonts/Poppins-Regular.ttf',
			},
			{
				src: '/fonts/Poppins-Bold.ttf',
				fontWeight: 'bold',
			},
			{
				src: '/fonts/Poppins-Light.ttf',
				fontWeight: 'light',
			},
		],
	});

	return (
		<>
			<AppDecorator>
				<NotificationsProvider />
				<AppRouter />
			</AppDecorator>
		</>
	);
};
