import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addDataPoints } from 'src/app/indexedDB/dataPointsDB/functions/addDataPoints';
import { clearDataPoints } from 'src/app/indexedDB/dataPointsDB/functions/clearDataPoints';
import { addDataPointTree } from 'src/app/indexedDB/dataPointsTreeDB/functions/addDataPointTree';
import { clearDataPointTree } from 'src/app/indexedDB/dataPointsTreeDB/functions/clearDataPointTree';
import { addDictionaries } from 'src/app/indexedDB/dictionaryDB/functions/addDictionaries';
import { clearDictionaries } from 'src/app/indexedDB/dictionaryDB/functions/clearDictionaries';
import { addPeerTotalScoreDataPointTree } from 'src/app/indexedDB/peerTotalScoreDataPointTreeDB/functions/addPeerTotalScoreDataPointTree';
import { clearPeerTotalScoreDataPointTree } from 'src/app/indexedDB/peerTotalScoreDataPointTreeDB/functions/clearPeerTotalScoreDataPointTree';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { ColumnsMeta, Dictionaries, ForgotPasswordREQ, ForgotPasswordRES, ResetPasswordREQ, ResetPasswordRES, SignInValues, ThemeName, UserData } from './types';

const NAME = UrlAPI.login;

// * API requests
const signIn = createAsyncThunk(`${NAME}/signIn`, async (payload: SignInValues, thunkAPI) => {
	const res = await apiRequest.postRequest<UserData>({
		url: NAME,
		payload,
		thunkAPI,
	});

	const dictionaries = await apiRequest.getRequest<Dictionaries>({
		url: UrlAPI.dictionaries,
		thunkAPI,
		token: res.token,
	});

	const columnsMeta = await apiRequest.getRequest<ColumnsMeta>({
		url: UrlAPI.columnsMetadata,
		thunkAPI,
		token: res.token,
	});

	await Promise.allSettled([dictionaries, columnsMeta]);

	const dictionariesDB = clearDictionaries().then(() => addDictionaries(dictionaries));

	const columnsMetaDB1 = clearDataPoints().then(() => addDataPoints(columnsMeta.dataPoints));
	const columnsMetaDB2 = clearDataPointTree().then(() => addDataPointTree(columnsMeta.dataPointTree));
	const columnsMetaDB3 = clearPeerTotalScoreDataPointTree().then(() => addPeerTotalScoreDataPointTree(columnsMeta.peerTotalScoreDataPointTree));

	await Promise.allSettled([dictionariesDB, columnsMetaDB1, columnsMetaDB2, columnsMetaDB3]);

	return res;
});

const forgotPassword = createAsyncThunk(`${NAME}/forgotPassword`, async (arg: ForgotPasswordREQ, thunkAPI) => {
	const { params } = arg;

	localStorage.setItem('temp_user_name', params.user_name);

	return await apiRequest.postRequest<ForgotPasswordRES>({
		url: `${NAME}/password`,
		params,
		thunkAPI,
	});
});

const resetPassword = createAsyncThunk(`${NAME}/resetPassword`, async (arg: ResetPasswordREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.putRequest<ResetPasswordRES>({
		url: `${NAME}/password`,
		params,
		thunkAPI,
	});
});

// * Reducer
export interface State {
	activeTheme: ThemeName;
	userData: UserData | null;
	resetStatus: string | null;
	status: RequestStatus;
}

export const initialState: State = {
	activeTheme: 'default',
	userData: null,
	resetStatus: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		logout: state => {
			state.userData = null;
		},
		storeUserData: (state, action: { payload: UserData }) => {
			state.userData = action.payload;
		},
		setTheme: (state, action: PayloadAction<'default' | 'bw'>) => {
			state.activeTheme = action.payload;
		},
		clearResetStatus: state => {
			state.resetStatus = null;
		},
	},
	extraReducers: builder => {
		builder.addCase(signIn.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(signIn.fulfilled, (state, action) => {
			state.userData = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(signIn.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(forgotPassword.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(forgotPassword.fulfilled, (state, action) => {
			state.status = RequestStatus.still;
			state.resetStatus = action.payload.status;
		});
		builder.addCase(forgotPassword.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(resetPassword.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(resetPassword.fulfilled, (state, action) => {
			state.status = RequestStatus.still;
			state.resetStatus = action.payload.status;
		});
		builder.addCase(resetPassword.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsAuth = {
	...slice.actions,
	signIn,
	forgotPassword,
	resetPassword,
};
