import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { RequestStatus } from 'src/shared/api/types';
import { ButtonsWrapper } from 'src/shared/ui/_layout/ButtonsWrapper';
import { ButtonSquared } from 'src/shared/ui/_buttons/ButtonsSquared';
import { PasswordField } from 'src/shared/ui/_form_fields/PasswordField';
import { StringInputOutlined } from 'src/shared/ui/_inputs/StringInputOutlined';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import { Modal } from 'src/shared/ui/Modal';
import * as Yup from 'yup';
import { actionsAuth } from '../_BLL/slice';
import { SignInValues } from '../_BLL/types';
import { ResetPasswordForm } from './ResetPasswordForm';
import s from './SignInForm.module.scss';

export const SignInForm: FC = () => {
	// * Selectors
	const status = useAppSelector(state => state.auth.status);

	// * Actions
	const dispatch = useAppDispatch();
	const { signIn, clearResetStatus } = actionsAuth;

	// * Form
	const schema = Yup.object().shape({
		username: Yup.string().required(),
		password: Yup.string().required(),
	});

	const formMethods = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, watch } = formMethods;

	const userName = watch('username');

	const onSubmit = (values: SignInValues) => {
		dispatch(signIn(values));
	};

	// * Reset password
	const [openResetPassword, setOpenResetPassword] = useState(false);

	const toggleResetPasswordModal = () => {
		setOpenResetPassword(prevState => !prevState);
		dispatch(clearResetStatus());
	};

	// * Conditions
	const isLoading = status === RequestStatus.loading;

	// * Render
	return (
		<>
			<Modal
				show={openResetPassword}
				toggleModal={toggleResetPasswordModal}
				Content={<ResetPasswordForm userName={userName} />}
				width={20}
			/>

			<form
				className={s.form}
				onSubmit={handleSubmit(onSubmit)}
			>
				<FormProvider {...formMethods}>
					<Controller
						name="username"
						control={control}
						render={({ field, fieldState }) => (
							<StringInputOutlined
								{...field}
								data-test="username"
								size="small"
								variant="outlined"
								label="User name"
								error={!!fieldState.error?.message}
								helperText={fieldState.error?.message}
							/>
						)}
					/>

					<PasswordField
						data-test="password"
						name="password"
						label="Password"
					/>

					{isLoading ? (
						<div className={s.preloaderWrapper}>
							<Preloader />
						</div>
					) : (
						<ButtonsWrapper direction="column">
							<ButtonSquared
								data-test="signIn"
								type="submit"
							>
								sign in
							</ButtonSquared>

							<a
								href="https://www.denominator.com/contact-us/"
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'none' }}
							>
								<ButtonSquared>register</ButtonSquared>
							</a>

							<ButtonSquared
								onClick={toggleResetPasswordModal}
								status={status}
							>
								forgot password
							</ButtonSquared>
						</ButtonsWrapper>
					)}
				</FormProvider>
			</form>
		</>
	);
};
