import { ColumnsMeta } from 'src/pages/AuthPage/_BLL/types';

const getNameById = (id: number, columnsMetaDataPoints: ColumnsMeta['dataPoints']) => {
	for (const [key, value] of Object.entries(columnsMetaDataPoints)) {
		if (+key === id) {
			return value.displayName;
		}
	}
};

export const columnsMetaHelper = {
	getNameById,
};
