import Dexie, { Table } from 'dexie';
import { DataPoint } from 'src/pages/AuthPage/_BLL/types';

export class MySubClassedDexie extends Dexie {
	peerTotalScoreDataPointTree!: Table<DataPoint>;

	constructor() {
		super('peerTotalScoreDataPointTree');
		this.version(1).stores({
			peerTotalScoreDataPointTree: 'displayName',
		});
	}
}

export const db = new MySubClassedDexie();
