import Dexie, { Table } from 'dexie';
import { DataPoint } from 'src/pages/AuthPage/_BLL/types';

export class MySubClassedDexie extends Dexie {
	dataPointTree!: Table<DataPoint>;

	constructor() {
		super('dataPointTree');
		this.version(1).stores({
			dataPointTree: '++key',
		});
	}
}

export const db = new MySubClassedDexie();
