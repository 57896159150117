import { DataPoint } from 'src/pages/AuthPage/_BLL/types';
import { db } from '../init';

export const addPeerTotalScoreDataPointTree = async (peerTotalScoreDataPointTree: DataPoint[]) => {
	try {
		await db.peerTotalScoreDataPointTree.bulkAdd(peerTotalScoreDataPointTree);
	} catch (error) {
		console.log(error);
	}
};
