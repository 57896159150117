import { Dictionaries } from 'src/pages/AuthPage/_BLL/types';
import { db } from '../init';

export const addDictionaries = async (dictionaries: Dictionaries) => {
	try {
		await db.dictionaries.bulkAdd(
			Object.entries(dictionaries).map(dictionary => ({
				name: dictionary[0] as keyof Dictionaries,
				value: dictionary[1],
			})),
		);
	} catch (error) {
		console.log(error);
	}
};
